var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "14",
        viewBox: "0 0 15 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_23165_41699)" } }, [
        _c("path", {
          attrs: {
            d: "M1.08301 2.33333V5.83333H4.58301",
            stroke: "white",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M13.917 11.6667V8.16667H10.417",
            stroke: "white",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M12.4522 5.25C12.1563 4.41396 11.6535 3.66649 10.9907 3.07733C10.3278 2.48817 9.52649 2.07653 8.66151 1.88082C7.79653 1.68511 6.89607 1.7117 6.04415 1.95812C5.19223 2.20454 4.41661 2.66275 3.78967 3.29L1.08301 5.83333M13.9163 8.16667L11.2097 10.71C10.5827 11.3373 9.80712 11.7955 8.9552 12.0419C8.10328 12.2883 7.20282 12.3149 6.33784 12.1192C5.47286 11.9235 4.67156 11.5118 4.0087 10.9227C3.34584 10.3335 2.84302 9.58604 2.54717 8.75",
            stroke: "white",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_23165_41699" } }, [
          _c("rect", {
            attrs: {
              width: "14",
              height: "14",
              fill: "white",
              transform: "translate(0.5)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }